import { Component, OnInit } from "@angular/core";
import { ContenfulService } from "../../services/contenful.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  documentLink: any = "";
  footerLinks = {
    "cmr-falabella": [],
    "servicio-al-cliente": [],
  };
  _statusDocumentLink = false;
  _statusFooterLinks = false;

  constructor(private _contentService: ContenfulService) {}

  async ngOnInit() {
    try {
      this._statusFooterLinks = true;
      const itemsFooterLinks = [
        {
          fields: {
            texto: "Términos y condiciones",
            url: "/terminos-condiciones",
            target: "_self",
            seccion: "cmr-falabella",
            order: 3,
          },
        },
        {
          fields: {
            texto: "Código de ética",
            url: "https://assets.ctfassets.net/s6vonmy5x76n/75M0M7a0XdBq9Soitw9x7I/8375dde5102e0e01cc7883e486c9497f/C__digo_de___tica_CMR_Falabella_2020.pdf",
            target: "_blank",
            seccion: "cmr-falabella",
            order: 6,
          },
        },
        {
          fields: {
            texto:
              "Asociación Protección Consumidores del Mercado Común del Sur Proconsumer c/ CMR Falabella S.A. s/ Ordinario (Expte. Nº 10.514/2020).",
            url: "https://assets.ctfassets.net/p6eyia4djstu/5c2p8hysua1y7HWRTGqOVR/6e01cebaad06e6647181f80b0f452495/Edicto_PROCONSUMER_C_FALABELLA_.pdf",
            target: "_blank",
            seccion: "cmr-falabella",
            order: 8,
          },
        },
        {
          fields: {
            texto:
              "Asociación por la Defensa de Usuarios y Consumidores  (ADUC) c/ Falabella S.A. (antes CMR Falabella S.A.) y Otros s/ Ordinario (Expte. 35200/2015).",
            url: "https://assets.ctfassets.net/s6vonmy5x76n/7zKME0BhLHR1B0sBkKIWS8/bebaca3d5eb570058c3ae971e0eb2a3f/asociacion-por-la-defensa.pdf",
            target: "_blank",
            seccion: "cmr-falabella",
            order: 9,
          },
        },
        {
          fields: {
            texto: "Información al usuario de servicios financieros",
            url: "/atencion-al-usuario-financiero",
            target: "_self",
            seccion: "servicio-al-cliente",
            order: 101,
          },
        },
        {
          fields: {
            texto:
              "CMR Falabella S.A.,Suipacha 1111,Piso 18,Ciudad Autónoma de Buenos Aires",
            url: "https://www.cmrfalabella.com.ar/",
            target: "_blank",
            seccion: "servicio-al-cliente",
            order: 161,
          },
        },
      ];
      for (const it of itemsFooterLinks) {
        const seccion = it["fields"]["seccion"] || "";
        const target = it["fields"]["target"] || "";
        const url = it["fields"]["url"] || "";
        const texto = it["fields"]["texto"] || "";
        if (
          seccion !== "" &&
          target !== "" &&
          url !== "" &&
          texto !== "" &&
          typeof this.footerLinks[seccion] !== "undefined"
        ) {
          this.footerLinks[seccion].push({
            seccion: seccion,
            target: target,
            url: url,
            texto: texto,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}
